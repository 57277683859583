import BasicTemplate from '@templates/BasicTemplate';
import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import Button from '@components/atoms/Button/Button';
import scrollTo from '@utils/scrollTo';

const PageContainer = styled.div`
  min-height: 100vh;
  padding: 100px 15px 300px 15px;
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledHeadline = styled(Headline)`
  font-size: ${({ theme }) => theme.headingL};
`;

const StyledParagraph = styled(Paragraph)`
  margin: 15px 0 30px 0;
`;

const NotFound = () => {
  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  return (
    <BasicTemplate title="Strony nie znaleziono">
      <PageContainer>
        <StyledHeadline as="h2" $isHeadline2 $isMainColor>
          Error 404!
        </StyledHeadline>
        <StyledParagraph>
          Najwidoczniej strona, której szukasz nie istnineje.
        </StyledParagraph>
        <Button>
          <a
            href="/"
            onClick={(e) => {
              handleScrollableLink(e, 'hero');
            }}
          >
            Strona główna
          </a>
        </Button>
      </PageContainer>
    </BasicTemplate>
  );
};

export default NotFound;
