import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import scrollTo from '@utils/scrollTo';

const StyledButton = styled.button<{ isRight?: boolean }>`
  background-color: ${({ theme }) => theme.primary};
  width: 120px;
  height: 40px;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: 0 20px 50px rgba(89, 180, 208, 0.5);
  margin-left: ${(props) => (props.isRight ? '30px' : '0')};
`;

const ButtonText = styled.p`
  color: #ffffff;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  a {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const Button: FC<Props> = ({ children, onClick, isRight, link }) => {
  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  return (
    <StyledButton isRight={isRight}>
      <ButtonText>
        <a href={link} onClick={onClick}>
          {children}
        </a>
      </ButtonText>
    </StyledButton>
  );
};

interface Props {
  onClick?: () => void;
  isRight?: boolean;
  link?: string;
}

export default Button;
